import CasingCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/CasingCentralWrapper.component.js"
import styles from "./App.module.css";
import { getStringCased } from "@jordibosch20/software_tools_package/dist/utils/casing.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <CasingCentralWrapper title={"Convert Casing"} f={getStringCased}></CasingCentralWrapper>
      </div>
      <div className={styles.text}>
        <h2>Convert casing</h2>
        <p>Ever wondered how you could bulk transform your text into multiple different casings? This is your tool.</p>
        <p>You can freely convert text to your prefered casing.</p>
        <div class="flex-shrink-0 flex flex-col relative items-end">
        <div>
        <div class="pt-0.5">
        <div class="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full"></div>
        </div>
        </div>
        </div>
        <div class="relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn">
        <div class="flex-col gap-1 md:gap-3">
        <div class="flex flex-grow flex-col max-w-full">
        <div data-message-author-role="assistant" data-message-id="5ecc3336-c048-4ad0-b3b1-b42457ace35a" class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
        <div class="markdown prose w-full break-words dark:prose-invert light">
        <p>This tool lets you convert words, characters, files, and letters into a variety of formats, including upper Case, Lower Case, camelCase, Capital Case, snake_case, Title Case, Pascal Case, Kebab Case, dot.case, Header-Case, CONSTANT_CASE, and more, freely with a single click.</p>
        <p></p>
        <p><strong></strong></p>
        <p><strong>How does this tool work?</strong>&nbsp;</p>
        <p>Only thing you need to do is copy the text you want to translate in the textarea of the left hand side of the screen. Once this is done, click, check result and it will be automatically shown in the right hand side textarea with a button to directly copy the translataion in the clipboard<strong></strong></p>
        <p></p>
        <p><strong>Which options are available?</strong></p>
        <ul>
        <li>You can choose the kind of casing to convert to using the first dropdown.</li>
        <li>You can choose if each line should be treated separatedly or not, that is, if the break line unicode should be equivalent to just the space or not. This is useful in some cases, but the default is different encodings for different lines.\.</li>
        </ul>
        <p><strong></strong></p>
        <p><strong>Which conversions to which casings does this tool support?</strong></p>
        <p>Lets use some examples with the word:</p>
        <p><em>"test string"</em></p>
        <ul>
        <li>lowercase: <em>test string</em></li>
        <li>uppercase: <em>TEST STRING</em></li>
        <li>Alternating case: <em>TeSt StRiNg</em></li>
        <li>camel case: <em>TeSt StRiNg</em></li>
        <li>Capital Case:&nbsp;<em>testString</em></li>
        <li>Snake Case:&nbsp;<em>test_string</em></li>
        <li>Title Case:&nbsp;<em>testString</em></li>
        <li>Pascal Case:&nbsp;<em>TestString</em></li>
        <li>Kebab Case:&nbsp;<em>test-string</em></li>
        <li>Dot case:&nbsp;<em>test.string</em></li>
        <li>Header Case:&nbsp;<em>Test-String</em></li>
        <li>Constant Case:&nbsp;<em>TEST_STRING</em></li>
        </ul>
        <p><strong></strong></p>
        <p><strong>Can I paste confidential text?</strong></p>
        <p>Your pasted text never leaves your browser. Our javascript website is just cline side and never does any communication with any server. You can be sure your text will never leave your browser/device.</p>
        <p></p>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;